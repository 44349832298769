import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

const checkPairedLogger = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        id,
        loggerqrcode,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/logger/loggerqrcode/${loggerqrcode}/ispaired/${id}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useCheckPairedLogger(id, loggerqrcode, enabled) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'checkPairedLogger',
            {
                id,
                loggerqrcode,
                token,
            },
        ],
        queryFn: checkPairedLogger,
        enabled,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useCheckPairedLogger
