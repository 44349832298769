import React from 'react'
import { useTranslation } from 'react-i18next'
import { user } from '@skycell-ag/auth'
import Dashboard, { DashboardCardItem } from 'shared/Dashboard'
import { Typography } from '@mui/material'
import mainDashboardWidgetMap from './mainDashboardWidgetMap'

import { useLocationContext } from '../Location'

import useStyles from './MainDashboard.style'

const MainDashboard = () => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { location } = useLocationContext()

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <div className={classes.userLocationTitle}>
                    <Typography
                        variant="h1"
                        className={classes.userLocationText}
                    >
                        {t('WELCOME_IN_LOCATION', {
                            username: `${user.data.firstName} ${user.data.lastName}`,
                            location: `${location.locationName}`,
                        })}
                    </Typography>
                </div>
                <Dashboard
                    widgets={mainDashboardWidgetMap}
                    widgetComponent={DashboardCardItem}
                    classes={{ container: classes.dashboard }}
                />
            </div>
        </div>
    )
}

export default MainDashboard
