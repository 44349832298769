import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'
import { requestBusinessObjects } from '@skycell-ag/shared-components'

const getContacts = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        emails,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'POST',
        url: 'contact/filter-request/all',
        data: {
            includeFilters: { email: emails },
            start: 0,
            rows: 50,
        },
        token,
    })
        .then((data) => {
            return data.data.items
        })
}

const useContacts = (emailsFilter) => {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getContacts',
            {
                emails: emailsFilter,
                token,
            },
        ],
        queryFn: getContacts,
        enabled: Boolean(emailsFilter && emailsFilter.length > 0),
        refetchOnWindowFocus: false,
        retry: false,
    })
}

export default useContacts
