import { useMutation } from 'react-query'
import { useJWTToken } from '@skycell-ag/auth'
import { dateToStr } from '@skycell-ag/shared-components'

import { useLocationContext } from 'App/Root/Location'
import {
    createProcess,
    updateProcess,
} from '../services/process'

function useSaveProcess() {
    const token = useJWTToken()
    const { location } = useLocationContext()

    return useMutation(({
        processTemplate,
        groups,
        processId,
        processGroupId,
        currentGroup,
    }) => {
        const createData = {
            ...processTemplate,
            createdOnZoned: dateToStr(new Date()),
            changedOnZoned: dateToStr(new Date()),
            location: { id: location.locationId },
            progressGroups: groups,
        }

        const extendedData = processId ? {
            id: processGroupId,
            steps: groups[currentGroup.progressGroup - 1].steps,
        } : createData

        if (processId) {
            return updateProcess({
                token,
                data: extendedData,
            })
        }
        return createProcess(token, extendedData)
    }, { retry: false })
}

export default useSaveProcess
