import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { logout } from '@skycell-ag/auth'

import TwoRowErrorPage from 'shared/ErrorPages/TwoRowErrorPage'
import useStyles from './TwoRowErrorPage.style'

const propTypes = { message: PropTypes.string.isRequired }

const ErrorViewWithLogout = ({ message }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <TwoRowErrorPage
            message={message}
            iconPath="skycenter/crash_robot.svg"
            iconAlt="logo"
        >
            <Button
                className={classes.button}
                onClick={logout}
            >
                {t('LOGOUT')}
            </Button>
        </TwoRowErrorPage>
    )
}

ErrorViewWithLogout.propTypes = propTypes

export default ErrorViewWithLogout
