import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'
import isEmpty from 'lodash/isEmpty'

const getLogger = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        number,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `logger/loggernumber/${number}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useGetLoggerByNumber(number, enabled) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getLoggerQRCodeByNumber',
            {
                number,
                token,
            },
        ],
        queryFn: getLogger,
        enabled: enabled && !isEmpty(number),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useGetLoggerByNumber
