import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

export const getBusinessObjectsEvidence = (id, token) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/processevidence/${id}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useEvidence(evidenceId, options = {}) {
    const jsmToken = useJWTToken()

    return useQuery({
        queryKey: [
            'getEvidence',
            {
                id: evidenceId,
                token: jsmToken,
            },
        ],
        queryFn: ({ queryKey: [
            _key, // eslint-disable-line no-unused-vars
            {
                id,
                token,
            },
        ] }) => { return getBusinessObjectsEvidence(id, token) },
        enabled: Boolean(evidenceId),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 10,
        ...options,
    })
}

export default useEvidence
