import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

import QUERY_KEYS from 'shared/utils/queryKeys'

const getBusinessObjectsAsset = (number, token) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/container/${number}/currentprocess`,
        token,
    })
        .then((data) => {
            if (data.status === 204) {
                return {}
            }
            return data.data
        })
}

function useAsset(assetNumber) {
    const jsmToken = useJWTToken()

    return useQuery({
        queryKey: [
            QUERY_KEYS.Asset,
            {
                number: assetNumber,
                token: jsmToken,
            },
        ],
        queryFn: ({ queryKey: [
            _key, // eslint-disable-line no-unused-vars
            {
                number,
                token,
            },
        ] }) => { return getBusinessObjectsAsset(number, token) },
        enabled: Boolean(assetNumber),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useAsset
