import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

const сheckSensorDataValidation = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        processId,
        stepId,
        loggerNumber,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/processevidence/${processId}/processevidencestep/${stepId}/logger/${loggerNumber}/sensor-data-validation`,
        token,
    })
        .then((response) => {
            // console.log('response', response)
            return response.data.isValid
        })
}

function useCheckSensorDataValidation({
    processId,
    stepId,
    loggerNumber,
    enabled,
}) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'checkSensorDataValidation',
            {
                processId,
                stepId,
                loggerNumber,
                token,
            },
        ],
        queryFn: сheckSensorDataValidation,
        enabled,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useCheckSensorDataValidation
