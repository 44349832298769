/* eslint-disable complexity */
import React, {
    useReducer,
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import isNumber from 'lodash/isNumber'
import { Typography } from '@mui/material'
import clsx from 'clsx'

import { createInputObject } from 'shared/utils/stepsUtils'
import useRenderStepContext from 'shared/ScanLoggerQrCodeStepTemplate/useRenderStepContext'
import ScanLoggerQrCodeStepTemplate from 'shared/ScanLoggerQrCodeStepTemplate'
import TwoColumnErrorPage from 'shared/ErrorPages/TwoColumnErrorPage'
import {
    initializeState,
    ACTIONS,
} from 'shared/ScanLoggerQrCodeStepTemplate/utils'
import { useEvidenceContext } from 'App/Root/ProcessPage/EvidenceContextProvider'
import useEvidence from 'App/Root/ProcessPage/hooks/useEvidence'
import getGlobalAssetUrl from 'shared/utils/getGlobalAssetUrl'
import QrCodeRetry from 'shared/QrCodeRetry'

import useStyles from './LoggerInstallation.style'
import reducer, { VIEWTYPES } from './LoggerInstallationStep.reducer'
import useCheckSensorDataValidation
    from './hooks/useCheckSensorDataValidation'

const propTypes = {
    step: PropTypes.shape({
        id: PropTypes.number.isRequired,
        stepName: PropTypes.string.isRequired,
        loggerType: PropTypes.string.isRequired,
        stepTitle: PropTypes.string.isRequired,
        isAnswered: PropTypes.bool,
        isRequired: PropTypes.bool.isRequired,
        userInput: PropTypes.shape({ loggerNumber: PropTypes.string }),
        loggerValidationThresholdInHours: PropTypes.number,
    }).isRequired,
    isSummaryView: PropTypes.bool,
    isNested: PropTypes.bool,
    inputCallback: PropTypes.func,
}

const defaultProps = {
    isSummaryView: false,
    isNested: false,
    inputCallback: undefined,
}

const LoggerInstallation = ({
    step,
    isSummaryView,
    isNested,
    inputCallback,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [
        state,
        dispatch,
    ] = useReducer(reducer, { step }, initializeState)

    const { evidence } = useEvidenceContext()
    const { data: evidenceData } = useEvidence(evidence?.evidenceId)
    const { data: isSensorDataValid } = useCheckSensorDataValidation({
        processId: evidence?.evidenceId,
        stepId: step.id,
        loggerNumber: state.loggerNumber,
        enabled: Boolean(state.qrCode)
            && Boolean(state.loggerNumber) && isNumber(step.loggerValidationThresholdInHours),
    })

    const onRetry = useCallback(() => {
        inputCallback(createInputObject(null, true), step.stepName)

        dispatch({ type: ACTIONS.RETRY })
    }, [
        inputCallback,
        step.stepName,
    ])

    const defaultContextRender = useRenderStepContext({
        step,
        state,
        ViewTypes: VIEWTYPES,
        Actions: ACTIONS,
        inputCallback,
        dispatch,
    })

    const notValidMessageRender = useMemo(() => {
        return (
            <div className={classes.validMessageContainer}>
                <div className={clsx(classes.messageContainer, classes.notValidBackground)}>
                    <img
                        alt="logo"
                        src={getGlobalAssetUrl('icons/warning.svg')}
                        className={classes.icon}
                    />
                    <Typography
                        variant="h5"
                        className={clsx(classes.messageText, classes.notValidMessageText)}
                    >
                        {t('LOGGER_INSTALLATION_VALIDATION_MESSAGE_NO_RECENT_DATA')}
                    </Typography>
                </div>
            </div>
        )
    }, [
        classes,
        t,
    ])

    const validMessageRender = useMemo(() => {
        return (
            <div className={classes.validMessageContainer}>
                <div className={clsx(classes.messageContainer, classes.validBackground)}>
                    <img
                        alt="logo"
                        src={getGlobalAssetUrl('skycenter/checkMark_green_slim.svg')}
                        className={classes.icon}
                    />
                    <Typography
                        variant="h5"
                        className={clsx(classes.messageText, classes.validMessageText)}
                    >
                        {t('LOGGER_INSTALLATION_VALIDATION_MESSAGE_OK')}
                    </Typography>
                </div>
            </div>
        )
    }, [
        classes,
        t,
    ])

    const stepContextRender = useMemo(() => {
        if (state.mode === VIEWTYPES.LINKED_ANOTHER
            || state.mode === VIEWTYPES.LINKED_TO_THIS_ALREADY) {
            const middleTexts = state.mode === VIEWTYPES.LINKED_ANOTHER ? [
                {
                    label: `${t('CONTAINER')}: `,
                    value: state.loggerData?.containerSerialNumber,
                },
                {
                    label: `${t('LOGGER')}: `,
                    value: state.loggerData?.loggerNumber,
                },
            ] : [{
                label: `${t('LOGGER')}: `,
                value: state.loggerData?.loggerNumber,
            }]

            const messageTopKey = state.mode === VIEWTYPES.LINKED_ANOTHER
                ? 'GENERIC_LOGGER_LINKED_ANOTHER_CONTAINER' : 'GENERIC_LOGGER_ALREADY_LINKED_CONTAINER'

            const messageBottomKey = state.mode === VIEWTYPES.LINKED_ANOTHER ? 'CONTACT_SKYCELL' : 'CONTACT_SKYCELL_LOGGER'

            return (
                <TwoColumnErrorPage
                    iconPath="skycenter/error_sorry.svg"
                    iconAlt="error"
                    buttonLabel={t('RETRY')}
                    onButtonClick={onRetry}
                    buttonDataTestId="retry-button"
                    messageTop={t(messageTopKey)}
                    messageBottom={t(messageBottomKey)}
                    middleTexts={middleTexts}
                />
            )
        }
        if ((state.mode === VIEWTYPES.CONFIRMED || state.mode === VIEWTYPES.CONFIRMED_AND_SAVED)
            && state.loggerNumber && isNumber(step.loggerValidationThresholdInHours)) {
            const validRender = isSensorDataValid ? validMessageRender : notValidMessageRender

            return (
                <QrCodeRetry
                    qrCode={state.qrCode}
                    loggerNumber={state.loggerNumber}
                    onRetry={onRetry}
                    validationMessageRender={validRender}
                />
            )
        }
        return defaultContextRender
    }, [
        defaultContextRender,
        onRetry,
        t,
        notValidMessageRender,
        validMessageRender,
        step.loggerValidationThresholdInHours,
        state,
        isSensorDataValid,
    ])

    return (
        <ScanLoggerQrCodeStepTemplate
            step={step}
            isSummaryView={isSummaryView}
            isNested={isNested}
            inputCallback={inputCallback}
            stepContextRender={stepContextRender}
            ViewTypes={VIEWTYPES}
            Actions={ACTIONS}
            state={state}
            dispatch={dispatch}
            evidenceData={evidenceData}
            needValidationRequest
        />
    )
}

LoggerInstallation.propTypes = propTypes
LoggerInstallation.defaultProps = defaultProps

export default LoggerInstallation
