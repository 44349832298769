import { useMutation } from 'react-query'
import { useJWTToken } from '@skycell-ag/auth'
import { requestBusinessObjects } from '@skycell-ag/shared-components'

import b64DataUrlToBlob from 'shared/utils/b64DataUrlToBlob'

import { useAttachmentContext } from '../AttachmentContextProvider'

export const createAttachment = (token, {
    file: fileDataUrl,
    fileName,
    description,
}) => {
    const imgFile = new FormData()
    const blob = b64DataUrlToBlob(fileDataUrl, 'image/png')

    imgFile.append('file', blob)
    imgFile.append('fileName', fileName)
    if (description) {
        imgFile.append('description', description)
    }
    return requestBusinessObjects({
        method: 'POST',
        url: 'attachment',
        token,
        data: imgFile,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then((data) => {
            return data.data
        })
}

function useSaveAttachments() {
    const token = useJWTToken()
    const { saveAttachments } = useAttachmentContext()

    return useMutation((items) => {
        const promises = items.map((item) => {
            return createAttachment(token, {
                file: item.file,
                fileName: item.fileName,
                description: item.description,
            })
        })

        return Promise.all(promises).then((values) => {
            return values.map((id, index) => {
                return {
                    ...items[index],
                    id,
                }
            })
        })
    }, {
        retry: false,
        onSuccess: (data) => {
            saveAttachments((previous) => {
                return {
                    ...previous,
                    attachments: [
                        ...previous.attachments,
                        ...data,
                    ],
                }
            })
        },
    })
}

export default useSaveAttachments
