import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

import QUERY_KEYS from 'shared/utils/queryKeys'

const getReadyToShip = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    {
        location,
        token,
    },
] }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/order/status?location=${location}&statuses=READY_TO_SHIP&statuses=BOOKED&statuses=RESERVED&statuses=TO_BE_PROVISIONED`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useReadyToShip(addressId) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            QUERY_KEYS.Shipments,
            {
                location: addressId,
                token,
            },
        ],
        queryFn: getReadyToShip,
        enabled: Boolean(addressId),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 500,
    })
}

export default useReadyToShip
