import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

const REFETCH_INTERVAL = 30000

export const getProcessAvailableContainers = ({
    locationId,
    process,
    tempRange,
}, token) => {
    const tempStr = tempRange ? `&containerTempRange=${tempRange}` : ''

    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/processevidence/location/${locationId}/availablecontainers?process=${process}${tempStr}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useProcessAvailableContainers({
    location,
    processType,
    temp,
    needRefetch = false,
}, enabled) {
    const jsmToken = useJWTToken()

    return useQuery({
        queryKey: [
            'getProcessAvailableContainers',
            {
                locationId: location,
                process: processType,
                token: jsmToken,
                tempRange: temp,
            },
        ],
        queryFn: ({ queryKey: [
            _key, // eslint-disable-line no-unused-vars
            {
                locationId,
                process,
                token,
                tempRange,
            },
        ] }) => {
            return getProcessAvailableContainers({
                locationId,
                process,
                tempRange,
            }, token)
        },
        enabled: Boolean(enabled),
        refetchOnWindowFocus: false,
        refetchInterval: needRefetch ? REFETCH_INTERVAL : false,
        retry: false,
        staleTime: 1000,
    })
}

export default useProcessAvailableContainers
