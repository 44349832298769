import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

const getBusinessObjectsAvailableProcesses = ({
    locationId,
    asset,
}, token) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `skycenter/processevidence/location/${locationId}/container/${asset}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

function useAssetProcesses({
    location,
    assetNumber,
}, enabled) {
    const jsmToken = useJWTToken()

    return useQuery({
        queryKey: [
            'getAssetProcesses',
            {
                locationId: location,
                asset: assetNumber,
                token: jsmToken,
            },
        ],
        queryFn: ({ queryKey: [
            _key, // eslint-disable-line no-unused-vars
            {
                locationId,
                asset,
                token,
            },
        ] }) => {
            return getBusinessObjectsAvailableProcesses({
                locationId,
                asset,
            }, token)
        },
        enabled: Boolean(enabled),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
        cacheTime: 1000,
    })
}

export default useAssetProcesses
