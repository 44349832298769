import { requestBusinessObjects } from '@skycell-ag/shared-components'
import { useJWTToken } from '@skycell-ag/auth'
import { useQuery } from 'react-query'

const getLocation = ({ queryKey: [
    _key, // eslint-disable-line no-unused-vars
    { token },
] }) => {
    return requestBusinessObjects({
        method: 'POST',
        url: 'location/filter-request/all',
        data: {
            includeFilters: { addressMainCategory: ['SERVICECENTER'] },
            start: 0,
            rows: 100,
            sortField: 'locationName',
            sortType: 'asc',
        },
        token,
    })
        .then((data) => {
            return data.data.items.map((location) => {
                return {
                    id: location.id,
                    name: location.locationName,
                }
            })
        })
}

function useLocation(enabled = false) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getServiceCenters',
            { token },
        ],
        queryFn: getLocation,
        enabled: Boolean(enabled),
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000,
    })
}

export default useLocation
