import {
    useMutation,
    useQueryClient,
} from 'react-query'
import { useJWTToken } from '@skycell-ag/auth'

import QUERY_KEYS from 'shared/utils/queryKeys'
import { completeProcess } from '../services/process'

function useCompleteProcess() {
    const token = useJWTToken()
    const queryClient = useQueryClient()

    return useMutation(({ processId }) => {
        return completeProcess(token, processId)
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(QUERY_KEYS.Asset)
        },
        useErrorBoundary: true,
    })
}

export default useCompleteProcess
