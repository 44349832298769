import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
    useAllowed,
    user,
} from '@skycell-ag/auth'

import authRoles from 'App/authRoles'
import NoAccessView from './NoAccessView'
import AccessContext from './AccessContext'

const propTypes = {
    children: PropTypes.element.isRequired,
    allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const AccessProvider = ({
    children,
    allowedRoles,
}) => {
    const allowed = useAllowed(...allowedRoles)
    const isAdmin = useMemo(() => {
        return (user?.data?.role.indexOf(authRoles.ADMIN) > -1)
    }, [])

    return (
        <AccessContext.Provider value={{ isAdmin }}>
            {allowed ? children
                : (
                    <NoAccessView />
                )}

        </AccessContext.Provider>
    )
}

AccessProvider.propTypes = propTypes

export default AccessProvider
